<template>
  <div class="new-detail">
    <div class="header">
      <router-link to="/" class="header-inner" tag="div">
        <img src="@/assets/icon/logo.png" alt="ELU">
      </router-link>
    </div>
    <div class="inner">
      <div class="title">{{ info.title }}</div>
      <div class="flex info">
        <div class="f1">{{ info.author }}<b v-if="info.tag"> | </b><span v-for="tag in formatTag(info.tag)" :key="tag" style="margin-right: 4px">{{ tag }}</span></div>
        <div class="tr">{{ info.publishTime }}</div>
      </div>
      <div class="content" v-html="info.body" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$http.get('/system/article/detail', { params: { id: this.$route.params.id }}).then(res => {
        this.info = res.data.data || {}
      })
    },
    formatTag(tagStr) {
      if (tagStr) {
        const str = tagStr.replace(/，/g,',')
        return str.split(',') || []
      }
      return []
    },
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 64px;
  background: #F5F8FF url('../assets/bg/all_bg.png') no-repeat scroll top / 100% auto;
  .header-inner {
    width: 700px;
    margin: 0 auto;
    padding: 18px;
    img {
      height: 26px;
    }
  }
}
.new-detail {
  background-color: #FFF;
  min-height: 100vh;
  .inner {
    width: 700px;
    padding: 20px;
    margin: 0 auto;
    background-color: #FFF;
    min-height: 100vh;
    .title {
      font-size: 22px;
      font-weight: bolder;
      margin-bottom: 16px;
      cursor: pointer;
      display: block;
      line-height: 32px;
    }
    .desc {
      height: 110px;
      font-size: 14px;
      line-height: 26px;
      color: #7F7F7F;
      overflow: hidden;
    }
    .info {
      color: #ADADAD;
      font-size: 14px;
    }
    .content {
      margin-top: 24px;
      line-height: 32px;
      text-align: justify;
    }
  }
}
</style>